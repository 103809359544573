import React from "react";
import styled from "styled-components";
import logo from "../assets/images/logos/Buffalo_Hz_Black.webp";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";

const XLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30px"
    height="30px"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M14.095479,10.316482L22.286354,1h-1.940718l-7.115352,8.087682L7.551414,1H1l8.589488,12.231093L1,23h1.940717  l7.509372-8.542861L16.448587,23H23L14.095479,10.316482z M11.436522,13.338465l-0.871624-1.218704l-6.924311-9.68815h2.981339  l5.58978,7.82155l0.867949,1.218704l7.26506,10.166271h-2.981339L11.436522,13.338465z" />
  </svg>
);

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <FooterContainer>
      <FooterContent>
        <LogoContainer>
          <img src={logo} alt="Habitat for Humanity Buffalo" />
        </LogoContainer>
        <FooterRow>
          <FooterColumn>
            <Office>
              <strong>Main Office</strong>
              <p>1675 South Park Avenue, Buffalo, NY 14220</p>
              <p>Tel: (716) 764-9548</p>
            </Office>
            <Office>
              <strong>South ReStore</strong>
              <p>1675 South Park Avenue, Buffalo, NY 14220</p>
              <p>Tel: (716) 204-0740 ext. 302</p>
            </Office>
            <Office>
              <strong>North ReStore</strong>
              <p>1030 Niagara Falls Blvd, Tonawanda, NY 14150</p>
              <p>Tel: (716) 204-0740 ext. 302</p>
            </Office>
          </FooterColumn>
          <FooterColumn>
            <LinkList>
              <li>
                <a href="/who-we-are">Who We Are</a>
              </li>
              <li>
                <a href="/get-involved">Get Involved</a>
              </li>
              <li>
                <a href="/support-us/donate-funds">Donate</a>
              </li>
              <li>
                <a href="/restore">ReStore</a>
              </li>
              <li>
                <a href="/projects/current">Our Projects</a>
              </li>
              <li>
                <a href="/contact">Contact Us</a>
              </li>
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
            </LinkList>
          </FooterColumn>
          <FooterColumn>
            <SocialIcons>
              <SocialIcon
                href="https://www.facebook.com/HabitatBuffalo"
                target="_blank"
                aria-label="Facebook"
              >
                <FaFacebook size={30} />
              </SocialIcon>
              <SocialIcon
                href="https://www.instagram.com/habitatbuffalo"
                target="_blank"
                aria-label="Instagram"
              >
                <FaInstagram size={30} />
              </SocialIcon>
              <SocialIcon
                href="https://www.linkedin.com/company/habitat-for-humanity-buffalo"
                target="_blank"
                aria-label="LinkedIn"
              >
                <FaLinkedin size={30} />
              </SocialIcon>
              <SocialIcon
                href="https://www.x.com" // Update this link with your X profile
                target="_blank"
                aria-label="X"
              >
                <XLogo />
              </SocialIcon>
              <SocialIcon
                href="https://www.youtube.com/user/HabitatBuffalo"
                target="_blank"
                aria-label="YouTube"
              >
                <FaYoutube size={30} />
              </SocialIcon>
            </SocialIcons>
          </FooterColumn>
        </FooterRow>
      </FooterContent>
      <FooterBottom>
        <p>
          &copy; {currentYear} Habitat for Humanity Buffalo. All rights
          reserved.
        </p>
      </FooterBottom>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  background-color: #f0f0f0;
  color: #333;
  padding: 20px 0;
  font-size: 14px;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FooterRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
  margin-top: 20px;

  @media (max-width: 930px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 930px) {
    margin-bottom: 10px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  img {
    height: 60px;
  }
`;

const Office = styled.div`
  margin-bottom: 10px;
  text-align: center;

  p {
    margin: 5px 0;
    line-height: 1.5;
  }
`;

const LinkList = styled.ul`
  list-style: none;
  padding: 0;

  li {
    margin-bottom: 10px;

    a {
      color: #333;
      font-weight: 500;
      text-decoration: none;
      font-size: 14px;

      &:hover {
        text-decoration: underline;
        color: #0093d0;
      }
    }
  }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;

  a {
    color: #333;
    font-size: 30px;
    text-decoration: none;

    &:hover {
      color: #0093d0;
    }
  }
`;

const SocialIcon = styled.a`
  color: inherit;
  &:hover {
    color: var(--primary-color);
  }
`;

const FooterBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  border-top: 1px solid #ccc;

  p {
    margin: 0;
    font-size: 12px;
  }
`;

export default Footer;
