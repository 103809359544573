import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom"; // Import Navigate
import App from "./App";
import ScrollToTop from "./components/ScrollToTop";
import PageTransition from "./components/PageTransition";
import Loading from "./components/Loading"; // Custom loading component
import RedirectToExternal from "./components/RedirectToExternal";

// Lazy load components
const Home = lazy(() => import("./pages/Home"));
const WhoWeAre = lazy(() => import("./pages/who-we-are/WhoWeAre"));
const GetInvolved = lazy(() => import("./pages/get-involved/GetInvolved"));
const SupportUs = lazy(() => import("./pages/support-us/SupportUs"));
const Restore = lazy(() => import("./pages/restore/Restore"));
const Donate = lazy(() => import("./pages/donate/Donate"));
const AboutHabitatBuffalo = lazy(() =>
  import("./pages/who-we-are/AboutHabitatBuffalo")
);
const OurHomes = lazy(() => import("./pages/who-we-are/OurHomes"));
const HabitatHomeowners = lazy(() =>
  import("./pages/who-we-are/HabitatHomeowners")
);
const Newsroom = lazy(() => import("./pages/who-we-are/Newsroom"));
const MeetHabitatBuffalo = lazy(() =>
  import("./pages/who-we-are/MeetHabitatBuffalo")
);
const CareerOpportunities = lazy(() =>
  import("./pages/who-we-are/CareerOpportunities")
);
const JobDetail = lazy(() => import("./pages/jobs/JobDetail"));
const AmericorpsJobDetail = lazy(() =>
  import("./pages/jobs/AmericorpsJobDetail")
);
const HowToApply = lazy(() => import("./pages/how-to-apply/HowToApply"));
const Homeownership = lazy(() => import("./pages/how-to-apply/Homeownership"));
const ProjectDetail = lazy(() => import("./pages/how-to-apply/ProjectDetail"));
const SpecialProjectDetail = lazy(() =>
  import("./pages/how-to-apply/SpecialProjectDetail")
);
const HomeRepairs = lazy(() => import("./pages/how-to-apply/HomeRepairs"));
const RepairDetail = lazy(() => import("./pages/how-to-apply/RepairDetail"));
const IndividualVolunteerOpportunities = lazy(() =>
  import("./pages/get-involved/IndividualVolunteerOpportunities")
);
const TeamBuildOpportunities = lazy(() =>
  import("./pages/get-involved/TeamBuildOpportunities")
);
const WinterWarriors = lazy(() =>
  import("./pages/get-involved/WinterWarriors")
);
const Events = lazy(() => import("./pages/get-involved/Events"));
const EventDetail = lazy(() => import("./pages/get-involved/EventDetail"));
const Advocate = lazy(() => import("./pages/get-involved/Advocate"));
const VeteransMilitaryFamilies = lazy(() =>
  import("./pages/get-involved/VeteransMilitaryFamilies")
);
const HabitatYoungProfessionals = lazy(() =>
  import("./pages/get-involved/HabitatYoungProfessionals")
);
const FaithAndCommunity = lazy(() =>
  import("./pages/get-involved/FaithAndCommunity")
);
const YouthPrograms = lazy(() => import("./pages/get-involved/YouthPrograms"));
const BecomeAContractor = lazy(() =>
  import("./pages/get-involved/BecomeAContractor")
);
const DonateItems = lazy(() => import("./pages/support-us/DonateItems"));
const DonateACar = lazy(() => import("./pages/support-us/DonateACar"));
const HostYourOwnFundraiser = lazy(() =>
  import("./pages/support-us/HostYourOwnFundraiser")
);
const LocationsAndHours = lazy(() =>
  import("./pages/restore/LocationsAndHours")
);
const WhatWeSell = lazy(() => import("./pages/restore/WhatWeSell"));
const ReStoreFAQ = lazy(() => import("./pages/restore/ReStoreFAQ"));
const ReStoreDonateItems = lazy(() =>
  import("./pages/restore/ReStoreDonateItems")
);
const BlogPost = lazy(() => import("./pages/blog/BlogPost"));
const BlogMain = lazy(() => import("./pages/blog/BlogMain"));
const CurrentProjects = lazy(() => import("./pages/projects/CurrentProjects"));
const CompletedProjects = lazy(() =>
  import("./pages/projects/CompletedProjects")
);
const SpecialProjects = lazy(() => import("./pages/projects/SpecialProjects"));
const NotFound = lazy(() => import("./pages/NotFound"));
const HabitatHappenings = lazy(() =>
  import("./pages/newsroom/HabitatHappenings")
);
const AllTheGoodStuff = lazy(() => import("./pages/newsroom/AllTheGoodStuff"));
const LatestNews = lazy(() => import("./pages/newsroom/LatestNews"));
const InTheMedia = lazy(() => import("./pages/newsroom/InTheMedia"));
const MediaContact = lazy(() => import("./pages/newsroom/MediaContact"));
const PressRelease = lazy(() => import("./pages/newsroom/PressRelease"));
const JobListings = lazy(() => import("./pages/jobs/JobListings"));
const AmericorpsListings = lazy(() =>
  import("./pages/jobs/AmericorpsListings")
);
const ReStores = lazy(() => import("./pages/volunteer/ReStores"));
const Remote = lazy(() => import("./pages/volunteer/Remote"));
const NonConstruction = lazy(() => import("./pages/volunteer/NonConstruction"));
const CrewLeaderTraining = lazy(() =>
  import("./pages/volunteer/CrewLeaderTraining")
);
const AmeriCorps = lazy(() => import("./pages/volunteer/AmeriCorps"));
const Construction = lazy(() => import("./pages/volunteer/Construction"));
const GiftsOfStock = lazy(() => import("./pages/support/GiftsOfStock"));
const GivingClubs = lazy(() => import("./pages/support/GivingClubs"));
const PlannedGiving = lazy(() => import("./pages/support/PlannedGiving"));
const TributeAndMemorialGifts = lazy(() =>
  import("./pages/support/TributeAndMemorialGifts")
);
const UniqueWaysToGive = lazy(() => import("./pages/support/UniqueWaysToGive"));
const WorkplaceGiving = lazy(() => import("./pages/support/WorkplaceGiving"));
const Athletic = lazy(() => import("./pages/support/Athletic"));
const SpecialOccasion = lazy(() => import("./pages/support/SpecialOccasion"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const Contact = lazy(() => import("./pages/Contact"));
const HomeRepairFormPage = lazy(() =>
  import("./pages/how-to-apply/HomeRepairFormPage")
);
const VolunteerWaiver = lazy(() => import("./pages/VolunteerWaiver"));

const SponsorDetail = lazy(() => import("./pages/SponsorDetail"));

ReactDOM.render(
  <Router>
    <ScrollToTop />
    <App>
      <PageTransition>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<Home />} />
            {/* Reroutes */}
            <Route
              path="/volunteer-registration"
              element={
                <RedirectToExternal url="https://forms.office.com/r/wq4vCpdjXt" />
              }
            />
            {/* Who We Are */}
            <Route path="/who-we-are" element={<WhoWeAre />} />
            <Route path="/about" element={<WhoWeAre />} />
            <Route
              path="/who-we-are/about-habitat-buffalo"
              element={<AboutHabitatBuffalo />}
            />
            <Route path="/who-we-are/our-homes" element={<OurHomes />} />
            <Route path="/projects" element={<OurHomes />} />
            <Route
              path="/who-we-are/habitat-homeowners"
              element={<HabitatHomeowners />}
            />
            <Route path="/who-we-are/newsroom" element={<Newsroom />} />
            <Route path="/newsroom" element={<Newsroom />} />
            <Route
              path="/who-we-are/meet-habitat-buffalo"
              element={<MeetHabitatBuffalo />}
            />
            <Route
              path="/who-we-are/career-opportunities"
              element={<CareerOpportunities />}
            />
            <Route path="/careers" element={<CareerOpportunities />} />
            <Route path="/jobs/job-listings" element={<JobListings />} />
            <Route
              path="/jobs/americorps-listings"
              element={<AmericorpsListings />}
            />
            <Route
              path="/who-we-are/career-opportunities/job/:id"
              element={<JobDetail />}
            />
            <Route
              path="/who-we-are/career-opportunities/americorps/:id"
              element={<AmericorpsJobDetail />}
            />
            {/* Newsroom */}
            <Route
              path="/newsroom/habitat-happenings"
              element={<HabitatHappenings />}
            />
            <Route
              path="/newsroom/all-the-good-stuff"
              element={<AllTheGoodStuff />}
            />
            <Route path="/newsroom/blog" element={<BlogMain />} />
            <Route path="/newsroom/latest-news" element={<LatestNews />} />
            <Route path="/newsroom/in-the-media" element={<InTheMedia />} />
            <Route path="/newsroom/media-contact" element={<MediaContact />} />
            <Route path="/newsroom/:id" element={<PressRelease />} />
            {/* How To Apply */}
            <Route
              path="/how-to-apply/homeownership"
              element={<Homeownership />}
            />
            <Route
              path="/how-to-apply/project/:id"
              element={<ProjectDetail />}
            />
            <Route
              path="/how-to-apply/special-project/:id"
              element={<SpecialProjectDetail />}
            />
            <Route path="/projects/current" element={<CurrentProjects />} />
            <Route path="/projects/completed" element={<CompletedProjects />} />
            <Route path="/projects/special" element={<SpecialProjects />} />
            <Route
              path="/how-to-apply/home-repairs"
              element={<HomeRepairs />}
            />
            <Route
              path="/apply-for-home-repairs"
              element={<HomeRepairFormPage />}
            />
            <Route
              path="/how-to-apply/home-repairs/:id"
              element={<RepairDetail />}
            />
            <Route path="/how-to-apply" element={<HowToApply />} />
            {/* Get Involved */}
            <Route path="/get-involved" element={<GetInvolved />} />
            <Route
              path="/get-involved/individual-volunteer-opportunities"
              element={<IndividualVolunteerOpportunities />}
            />
            <Route
              path="/get-involved/team-build-opportunities"
              element={<TeamBuildOpportunities />}
            />
            <Route
              path="/get-involved/winter-warriors"
              element={<WinterWarriors />}
            />
            <Route path="/get-involved/events" element={<Events />} />
            <Route path="/get-involved/events/:id" element={<EventDetail />} />
            <Route path="/get-involved/advocate" element={<Advocate />} />
            <Route
              path="/get-involved/veterans-military-families"
              element={<VeteransMilitaryFamilies />}
            />
            <Route
              path="/get-involved/habitat-young-professionals"
              element={<HabitatYoungProfessionals />}
            />
            <Route
              path="/get-involved/faith-and-community"
              element={<FaithAndCommunity />}
            />
            <Route
              path="/get-involved/youth-programs"
              element={<YouthPrograms />}
            />
            <Route
              path="/get-involved/become-a-habitat-buffalo-contractor"
              element={<BecomeAContractor />}
            />
            {/* Support us */}
            <Route path="/support-us" element={<SupportUs />} />
            <Route path="/support-us/donate-items" element={<DonateItems />} />
            <Route path="/support-us/donate-funds" element={<Donate />} />
            <Route path="/support-us/donate-a-car" element={<DonateACar />} />
            <Route
              path="/support-us/host-your-own-fundraiser"
              element={<HostYourOwnFundraiser />}
            />
            <Route
              path="/support-us/other-ways-to-give"
              element={<SupportUs />}
            />
            {/* ReStore */}
            <Route path="/restore" element={<Restore />} />
            <Route
              path="/restore/locations-and-hours"
              element={<LocationsAndHours />}
            />
            <Route path="/restore/what-we-sell" element={<WhatWeSell />} />
            <Route path="/restore/restore-faq" element={<ReStoreFAQ />} />
            <Route
              path="/restore/donate-items"
              element={<ReStoreDonateItems />}
            />
            {/* Donate*/}
            <Route path="/donate" element={<Donate />} />
            <Route path="/donate/donate-now" element={<Donate />} />
            <Route path="/donate/donate-monthly" element={<Donate />} />
            {/* Blog */}
            <Route path="/blog" element={<BlogMain />} />
            <Route path="/blog/:id" element={<BlogPost />} />
            {/* Projects */}
            <Route path="/projects/current" element={<CurrentProjects />} />
            <Route path="/projects/completed" element={<CompletedProjects />} />
            <Route path="/projects/special" element={<SpecialProjects />} />
            {/* Volunteer */}
            <Route path="/volunteer/construction" element={<Construction />} />
            <Route path="/volunteer/restores" element={<ReStores />} />
            <Route path="/volunteer/remote" element={<Remote />} />
            <Route
              path="/volunteer/non-construction"
              element={<NonConstruction />}
            />
            <Route
              path="/volunteer/crew-leader-training"
              element={<CrewLeaderTraining />}
            />
            <Route path="/volunteer/americorps" element={<AmeriCorps />} />
            <Route path="/volunteer" element={<GetInvolved />} />
            <Route path="/volunteer-waiver" element={<VolunteerWaiver />} />
            {/* Support */}
            <Route path="/support/gifts-of-stock" element={<GiftsOfStock />} />
            <Route path="/support/giving-clubs" element={<GivingClubs />} />
            <Route path="/support/planned-giving" element={<PlannedGiving />} />
            <Route
              path="/support/tribute-memorial-gifts"
              element={<TributeAndMemorialGifts />}
            />
            <Route
              path="/support/unique-ways-to-give"
              element={<UniqueWaysToGive />}
            />
            <Route
              path="/support/workplace-giving"
              element={<WorkplaceGiving />}
            />
            <Route path="/support/athletic" element={<Athletic />} />
            <Route
              path="/support/special-occasion"
              element={<SpecialOccasion />}
            />
            {/* Other */}
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/sponsors/:id" element={<SponsorDetail />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </PageTransition>
    </App>
  </Router>,
  document.getElementById("root")
);
